import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

export default function Venue(props) {
  const [page, setPage] = useState([]);

  function createLayout(venue, bodyContent) {
    let page = (
      <div>
        <Typography variant="h4">Venue</Typography>
        <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
      </div>
    );
    setPage(page);
    // return page;
  }

  useEffect(() => {
    let bodyContent = props?.content;
    createLayout(null, bodyContent);
  }, [props]);

  return page;
}
